module.exports = {
  "IDMClientTokenKey": "a6fba364-a217-5084-b601-1072b0ad3ddd",
  "IDMClientTokenSecret": "61ad7705-3f86-5f4d-9fbe-f0b806688e15",
  "IDMClientOptions": {
    "autoEnrollRole": [
      "b535f352-c7f_CCPO",
      "d11b5145-69f_BC___Students_Portal"
    ],
    "externalAuth": true,
    "externalAuthDomain": "portal-stage.bccsa-services.ca",
    "externalAuthPath": "/login",
    "externalValidatePath": "/validate",
    "externalRegistrationPath": "/register",
    "externalProfilePath": "/profile",
    "apiEndpoint": "https://api-auth-stage.bccsa-services.ca",
    "cookiesEndpoint": "https://portal-stage.bccsa-services.ca",
    "roles": {
      "USER": "b535f352-c7f_CCPO",
      "ADMIN": "e8d0b251-99f_CCPO",
      "ADVISOR": "cb517dbf-665_CCPO",
      "IDM_USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "SHARED_MODULES": "d11b5145-69f_BC___Students_Portal"
    },
    "partitions": {
      "CCPO": "ca.bccsa.ccpo",
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "CSPFile": "stage.js",
  "ApplicationName": "CCPO - Stage",
  "ApplicationAPIEndpoint": "https://drey7arze5.execute-api.ca-central-1.amazonaws.com/stage",
  "ApplicationTenantID": "98",
  "SMOrganizationEndpoint": "https://api-org-stage.bccsa-services.ca",
  "SMOrganizationTenantID": "CCPOBC-01S",
  "LogRocketOptions": {
    "applicationId": "ikon-dev/bccsa"
  },
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "profile": "/profile",
    "workHours": "/workhours",
    "userWorkHours": "/workhours/:id",
    "certification": "/cert/:id",
    "userCertification": "/cert/:id/:id2",
    "reports": "/reports",
    "settings": "/settings",
    "userDashboard": "/user/:id",
    "userProfile": "/user/:id/profile",
    "userSearch": "/users",
    "pendingWaiveRequests": "/waives/pending",
    "adminCertification": "/certifications",
    "usersImport": "/importUsers",
    "statusReport": "/statusReport",
    "adminBillingView": "/billing/:id",
    "simpleBillingView": "/billing"
  },
  "Stage": "stage"
}